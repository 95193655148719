import React from 'react';

import blueStyles from '../assets/styles/ChipButtonBlue.module.css';
import redStyles from '../assets/styles/ChipButtonRed.module.css';

import Spinner from './Spinner';

type ButtonType = 'FILLED' | 'BORDERED';
type ButtonColor = 'BLUE' | 'RED';

type Props = {
  color?: ButtonColor;
  disabled?: boolean;
  icon?: JSX.Element;
  iconSide?: 'LEFT' | 'RIGHT';
  label: string;
  loading?: boolean;
  loadingLabel?: string;
  maxWidth?: string;
  onPress: () => void;
  type?: ButtonType;
  width?: string;
};

function ChipButton(props: Props) {
  const {
    color = 'BLUE',
    disabled = false,
    icon,
    iconSide = 'RIGHT',
    label,
    loading = false,
    loadingLabel = '',
    maxWidth,
    onPress,
    type = 'FILLED',
    width,
  } = props;

  const stylesByColor: Record<
    ButtonColor,
    {
      readonly [key: string]: string;
    }
  > = {
    BLUE: blueStyles,
    RED: redStyles,
  };
  const styles = stylesByColor[color];

  function _chooseContainerStyle() {
    switch (type) {
      case 'BORDERED':
        return disabled
          ? styles.disabledBorderedContainer
          : styles.borderedContainer;
      case 'FILLED':
      default:
        return disabled
          ? styles.disabledFilledContainer
          : styles.filledContainer;
    }
  }

  function _chooseIconStyle() {
    if (disabled) return styles.grayIcon;
    if (type === 'BORDERED') return styles.mainColorIcon;
    return styles.whiteIcon;
  }

  function _chooseLabelStyle() {
    if (disabled) return styles.grayLabel;
    if (type === 'BORDERED') return styles.mainColorLabel;
    return styles.whiteLabel;
  }

  function _renderIcon() {
    const iconStyle =
      iconSide === 'RIGHT' ? { marginLeft: '8px' } : { marginRight: '8px' };
    return (
      <div className={_chooseIconStyle()} style={iconStyle}>
        {loading ? <Spinner /> : icon}
      </div>
    );
  }

  function _renderLabel() {
    return (
      <div className={_chooseLabelStyle()}>
        {loading ? loadingLabel : label}
      </div>
    );
  }

  function _getContainerWidthStyles(): React.CSSProperties {
    const style: React.CSSProperties = {};
    if (width) style.width = width;
    if (maxWidth) style.maxWidth = maxWidth;
    return style;
  }

  return (
    <button
      className={_chooseContainerStyle()}
      style={_getContainerWidthStyles()}
      onClick={onPress}
      disabled={disabled}
      data-testid={label}
    >
      {iconSide === 'RIGHT' ? _renderLabel() : null}
      {icon || loading ? _renderIcon() : null}
      {iconSide === 'LEFT' ? _renderLabel() : null}
    </button>
  );
}

export default ChipButton;
