import Resizer from 'react-image-file-resizer';

interface Settings {
  compressFormat?: string;
  maxSize?: number;
  quality?: number;
  rotation?: number;
}

const defaultSettings = {
  compressFormat: 'JPEG',
  maxSize: 3600,
  quality: 0.9,
  rotation: 0,
};

export const resizeImageFile = (
  file: File,
  settings: Settings = defaultSettings
) =>
  new Promise<File>((resolve, reject) => {
    const maxWidth = settings.maxSize || defaultSettings.maxSize;
    const maxHeight = settings.maxSize || defaultSettings.maxSize;
    const compressFormat =
      settings.compressFormat || defaultSettings.compressFormat;
    const quality = settings.quality
      ? settings.quality * 100
      : defaultSettings.quality * 100;
    const rotation = settings.rotation || defaultSettings.rotation;
    const outputType = 'blob';

    Resizer.imageFileResizer(
      file,
      maxWidth,
      maxHeight,
      compressFormat,
      quality,
      rotation,
      (newFileOrError) => {
        const blobToFile = (blob: Blob) => {
          const blobExtension = blob.type.split('/')[1];
          // Removes the old file's extension and replaces with the compress format extension
          const fileName = file.name.replace(
            /\.[0-9a-z]+$/i,
            `.${blobExtension}`
          );
          return new File([blob], fileName, { type: blob.type });
        };

        if (newFileOrError instanceof ProgressEvent) {
          if (newFileOrError.target && newFileOrError.target.error) {
            reject(newFileOrError.target.error);
          } else if (newFileOrError instanceof Error) {
            reject(newFileOrError);
          } else {
            reject(new Error('Unexpected error while compressing image'));
          }
        } else if (!(newFileOrError instanceof Blob)) {
          reject(new Error(newFileOrError));
        } else {
          if (newFileOrError.size < file.size) {
            resolve(blobToFile(newFileOrError));
          } else {
            resolve(file);
          }
        }
      },
      outputType
    );
  });
