import React from 'react';

import Header from '../generic-components/Header';
import styles from '../assets/styles/SubmissionComplete.module.css';

interface Props {
  code: string;
  onNewSubmissionPress: () => void;
}

function SubmissionComplete(props: Props) {
  const { code, onNewSubmissionPress } = props;
  const headerText = 'Pronto. Fotos enviadas!';
  const descriptionText = `Agora é só digitar o código abaixo no computador e continuar a criação da publicação por lá.`;

  return (
    <div className={styles.container}>
      <Header headerText={headerText} />
      <div className={styles.descriptionText}>{descriptionText}</div>
      <div className={styles.codeContainer}>
        <div className={styles.codeText}>{code}</div>
      </div>
      <div className={styles.newSubmission} onClick={onNewSubmissionPress}>
        Fazer novo envio
      </div>
    </div>
  );
}

export default SubmissionComplete;
