import React from 'react';

import GeekieLogo from '../assets/svg/geekie-logo.svg';
import styles from '../assets/styles/Header.module.css';

interface Props {
  headerText: string;
}

function Header(props: Props) {
  const { headerText } = props;

  return (
    <div className={styles.headerContainer}>
      <img src={GeekieLogo} alt={'Geekie logo'} width={152} height={32} />
      <div className={styles.horizontalLine} />
      <div className={styles.headerText}>{headerText}</div>
    </div>
  );
}

export default Header;
