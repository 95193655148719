import { apiPath } from './constants';

async function handleErrors(response: Response) {
  if (response.ok) return response;
  throw Error(await response.text());
}

async function uploadImage(imageFile: File) {
  const formData = new FormData();
  formData.append('file', imageFile);
  return await fetch(`${apiPath}/media-upload`, {
    method: 'post',
    body: formData,
  })
    .then(handleErrors)
    .then(async (response) => {
      const jsonResponse = await response.json();
      return jsonResponse.uri as string;
    });
}

async function getMediaCode(imageUrls: string[]) {
  return await fetch(`${apiPath}/media-code`, {
    headers: { 'Content-Type': 'application/json' },
    method: 'post',
    body: JSON.stringify(imageUrls),
  })
    .then(handleErrors)
    .then(async (response) => {
      const url = await response.text();
      return url;
    });
}

export { uploadImage, getMediaCode };
