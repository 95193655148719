import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import ErrorIcon from '@mui/icons-material/Error';

import styles from '../assets/styles/PhotoListCard.module.css';
import Spinner from '../generic-components/Spinner';
import colors from '../assets/styles/common/colors.module.css';

interface Props {
  errorType: string | null;
  image: File;
  onDeleteMediaPress: () => void;
  onWarningCardPress: () => void;
  status: string;
}

function PhotoListCard(props: Props) {
  const { errorType, image, onDeleteMediaPress, onWarningCardPress, status } =
    props;

  const LoadingCard = () => {
    return (
      <div
        className={styles.container}
        aria-label="aguardando o carregamento do anexo"
      >
        <div className={styles.directionRow}>
          <div className={styles.loadingImagePlaceholder} />
          <div className={styles.loadingTextPlaceholder} />
        </div>
        <Spinner color={colors.BUTTON_BLUE_LIGHT} />
      </div>
    );
  };

  const PermanentErrorCard = () => {
    return (
      <div
        className={styles.errorContainer}
        aria-label="a extensão do anexo não é suportada"
      >
        <div className={styles.directionRow}>
          <div className={styles.errorImagePlaceholder} />
          <div className={styles.errorFileName}>{image.name}</div>
          <button
            aria-label={`Remover o arquivo ${image.name}`}
            className={styles.permanentErrorButton}
            onClick={onDeleteMediaPress}
          >
            <DeleteIcon />
          </button>
        </div>
      </div>
    );
  };

  const TransientErrorCard = () => {
    return (
      <div
        aria-label="resolver erro de upload"
        className={styles.transientErrorContainer}
        onClick={onWarningCardPress}
      >
        <div className={styles.directionRow}>
          <div className={styles.transientErrorImagePlaceholder} />
          <div className={styles.transientErrorFileName}>{image.name}</div>
          <div className={styles.transientErrorButton}>
            <ErrorIcon />
          </div>
        </div>
      </div>
    );
  };

  const SuccessCard = () => {
    return (
      <div
        className={styles.container}
        aria-label="anexo carregado com sucesso"
      >
        <div className={styles.directionRow}>
          <img
            className={styles.imageWrapper}
            src={URL.createObjectURL(image)}
            alt={image.name}
          />
          <div className={styles.fileName}>{image.name}</div>
          <button
            aria-label={`Remover o arquivo ${image.name}`}
            className={styles.closeButton}
            onClick={onDeleteMediaPress}
          >
            <DeleteIcon />
          </button>
        </div>
      </div>
    );
  };

  const Card = () => {
    switch (status) {
      case 'PENDING':
        return <LoadingCard />;
      case 'FAILED':
        return errorType === 'PERMANENT' ? (
          <PermanentErrorCard />
        ) : (
          <TransientErrorCard />
        );
      case 'SUCCESS':
        return <SuccessCard />;
      default:
        return null;
    }
  };

  return <Card />;
}

export default PhotoListCard;
