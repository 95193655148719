import React from 'react';

import ChipButton from '../generic-components/ChipButton';
import Header from '../generic-components/Header';
import styles from '../assets/styles/Home.module.css';
import { invariant } from '../helpers/invariant';

interface Props {
  onSelectMediaFiles: (files: FileList) => void;
}

function Home(props: Props) {
  const { onSelectMediaFiles } = props;
  const headerText = 'Envie suas fotos';
  const descriptionText = `Escolha as fotos que deseja inserir na publicação. Elas serão enviadas com segurança para o seu computador.`;

  const onAddImageClick = () => {
    document.getElementById('input-image')?.click();
  };

  return (
    <div className={styles.container}>
      <Header headerText={headerText} />
      <div className={styles.descriptionText}>{descriptionText}</div>
      <div className={styles.button}>
        <ChipButton
          label={'Adicionar fotos'}
          onPress={onAddImageClick}
          type={'FILLED'}
          width={'100%'}
          maxWidth={'600px'}
        />
        <input
          id="input-image"
          data-testid="input-image"
          name="files"
          type="file"
          onChange={(event) => {
            invariant(
              event.target.files,
              'expected non-null event.target.files after file selection'
            );
            onSelectMediaFiles(event.target.files);
          }}
          accept="image/jpg, image/jpeg, image/png"
          hidden
          multiple
        />
      </div>
    </div>
  );
}

export default Home;
