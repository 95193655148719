import React from 'react';
import CheckIcon from '@mui/icons-material/Check';
import WarningIcon from '@mui/icons-material/Warning';

import Header from '../generic-components/Header';
import ChipButton from '../generic-components/ChipButton';
import homeStyles from '../assets/styles/Home.module.css';
import photoListStyles from '../assets/styles/PhotoList.module.css';
import { ImageState } from '../App';
import { invariant } from '../helpers/invariant';

import PhotoListCard from './PhotoListCard';
import MediaOptionsModal from './MediaOptionsModal';

interface Props {
  imageStates: ImageState[];
  isSubmitting: boolean;
  modalMediaId: string;
  onCloseMediaOptionsModal: () => void;
  onDeleteMediaPress: (id: string) => void;
  onReuploadMediaPress: () => void;
  onSelectMediaFiles: (files: FileList) => void;
  onSendMediasPress: () => void;
  onWarningCardPress: (id: string) => void;
  showErrorMessage: boolean;
}

function PhotoList(props: Props) {
  const {
    imageStates,
    isSubmitting,
    modalMediaId,
    onCloseMediaOptionsModal,
    onDeleteMediaPress,
    onReuploadMediaPress,
    onSelectMediaFiles,
    onSendMediasPress,
    onWarningCardPress,
    showErrorMessage,
  } = props;
  const headerText = 'Envie suas fotos';
  const fileNotSupported = 'Exclua os arquivos não suportados antes do envio';
  const failedToSendData =
    'Ocorreu um erro ao enviar os dados. Tente novamente.';

  const showPermanentErrorMessage = imageStates.some(
    (imageState) =>
      imageState.status === 'FAILED' && imageState.errorType === 'PERMANENT'
  );

  const renderImageCards = () => {
    if (imageStates?.length) {
      return imageStates.map((imageState) => {
        const { file: image, id, status } = imageState;
        return (
          <PhotoListCard
            errorType={status === 'FAILED' ? imageState.errorType : null}
            image={image}
            key={id}
            onDeleteMediaPress={() => onDeleteMediaPress(id)}
            status={status}
            onWarningCardPress={() => onWarningCardPress(id)}
          />
        );
      });
    }
    return null;
  };
  const checkIcon = <CheckIcon />;
  const onAddImageClick = () => {
    document.getElementById('input-image')?.click();
  };

  const renderErrorMessage = (message: string) => {
    return (
      <div className={photoListStyles.warningContainer}>
        <WarningIcon fontSize="small" />
        <div className={photoListStyles.warningText}>{message}</div>
      </div>
    );
  };

  return (
    <div className={homeStyles.container}>
      <Header headerText={headerText} />
      {showPermanentErrorMessage ? renderErrorMessage(fileNotSupported) : null}
      <div className={photoListStyles.cardsContainer}>{renderImageCards()}</div>
      <div className={photoListStyles.buttonsContainer}>
        <ChipButton
          label="Adicionar fotos"
          width="50%"
          type="BORDERED"
          onPress={onAddImageClick}
        />
        <ChipButton
          label="Enviar"
          loading={isSubmitting}
          loadingLabel="Enviando..."
          width="50%"
          icon={checkIcon}
          onPress={onSendMediasPress}
          disabled={imageStates.some(({ status }) => status !== 'SUCCESS')}
        />
      </div>
      {showErrorMessage ? (
        <div className={photoListStyles.warningTopMargin}>
          {renderErrorMessage(failedToSendData)}
        </div>
      ) : null}
      <input
        id="input-image"
        data-testid="input-more-image"
        name="files"
        type="file"
        onChange={(event) => {
          invariant(
            event.target.files,
            'expected non-null event.target.files after file selection'
          );
          onSelectMediaFiles(event.target.files);
        }}
        accept="image/jpg, image/jpeg, image/png"
        hidden
        multiple
      />
      <MediaOptionsModal
        isOpen={!!modalMediaId}
        mediaId={modalMediaId}
        onCloseModal={onCloseMediaOptionsModal}
        onDeleteMediaPress={onDeleteMediaPress}
        onReuploadMediaPress={onReuploadMediaPress}
      />
    </div>
  );
}

export default PhotoList;
