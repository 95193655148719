import React from 'react';
import Modal from 'react-modal';
import RefreshIcon from '@mui/icons-material/Refresh';
import DeleteIcon from '@mui/icons-material/Delete';

import styles from '../assets/styles/MediaOptionsModal.module.css';
import ChipButton from '../generic-components/ChipButton';

if (process.env.NODE_ENV !== 'test') Modal.setAppElement('#root');

type Props = {
  isOpen: boolean;
  mediaId: string;
  onCloseModal: () => void;
  onDeleteMediaPress: (id: string) => void;
  onReuploadMediaPress: () => void;
};

const MediaOptionsModal = (props: Props) => {
  const {
    isOpen,
    mediaId,
    onCloseModal,
    onDeleteMediaPress,
    onReuploadMediaPress,
  } = {
    ...props,
  };

  const descriptionText = 'O que deseja fazer com o anexo?';

  return (
    <Modal
      isOpen={isOpen}
      className={styles.container}
      overlayClassName={styles.overlay}
    >
      <div className={styles.descriptionText}>{descriptionText}</div>
      <ChipButton
        color={'BLUE'}
        icon={<RefreshIcon />}
        iconSide={'LEFT'}
        label={'Tentar novamente'}
        onPress={onReuploadMediaPress}
        type={'BORDERED'}
      />
      <ChipButton
        color={'RED'}
        icon={<DeleteIcon />}
        iconSide={'LEFT'}
        label={'Excluir anexo'}
        onPress={() => onDeleteMediaPress(mediaId)}
        type={'BORDERED'}
      />
      <div className={styles.cancelButton} onClick={onCloseModal}>
        Cancelar
      </div>
    </Modal>
  );
};

export default MediaOptionsModal;
