import React, { useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { v4 as uuid } from 'uuid';
import { omit } from 'lodash-es';

import { resizeImageFile } from './services/image';
import Home from './views/Home';
import PhotoList from './views/PhotoList';
import SubmissionComplete from './views/SubmissionComplete';
import './assets/fonts/fonts.css';
import { uploadImage, getMediaCode } from './apiCalls';

function setupSentry() {
  if (process.env.NODE_ENV !== 'production') {
    return;
  }
  const SENTRY_DSN =
    'https://3f0df84fff1441b99052b1a43801faa4@o12728.ingest.sentry.io/6655929';
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

setupSentry();

type Scene = 'HOME' | 'PHOTO_LIST' | 'SUBMISSION_COMPLETE';

type ErrorType = 'TRANSIENT' | 'PERMANENT';

type ImagePendingStateFields = {
  status: 'PENDING';
};

type ImageFailedStateFields = {
  status: 'FAILED';
  errorType: ErrorType;
};

type ImageSuccessStateFields = {
  status: 'SUCCESS';
  url: string;
};

export type ImageState = {
  id: string;
  file: File;
  isCompressed: boolean;
} & (
  | ImagePendingStateFields
  | ImageSuccessStateFields
  | ImageFailedStateFields
);

type ImageStatesById = {
  [id: string]: ImageState;
};

const ERROR_MESSAGES = [
  'File type not allowed, allowed types: image/jpeg,image/jpg,image/png',
  'File name not present',
];

function App() {
  const [scene, setScene] = useState<Scene>('HOME');
  const [imageStatesById, setImageStatesById] = useState<ImageStatesById>({});
  const [code, setCode] = useState<string>('');
  const [showSubmitError, setShowSubmitError] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [mediaOptionsModalId, setMediaOptionsModalId] = useState<string>('');

  const updateImageState = (imageState: ImageState) => {
    setImageStatesById((state) => ({ ...state, [imageState.id]: imageState }));
  };

  const uploadSingleImage = (imageState: ImageState) => {
    imageState.status = 'PENDING';
    updateImageState(imageState);

    let finalImageState: ImageState;
    uploadImage(imageState.file)
      .then((url) => {
        finalImageState = {
          ...imageState,
          status: 'SUCCESS',
          url,
        };
      })
      .catch((error) => {
        const errorType = ERROR_MESSAGES.includes(error.message)
          ? 'PERMANENT'
          : 'TRANSIENT';
        finalImageState = {
          ...imageState,
          status: 'FAILED',
          errorType: errorType,
        };
      })
      .finally(() => {
        updateImageState(finalImageState);
      });
  };

  const handleNewSubmissionPress = () => {
    setImageStatesById({});
    setCode('');
    setScene('HOME');
  };

  const getImageInitialStates = (images: File[]) => {
    const imageStates: ImageStatesById = images.reduce((states, file) => {
      const id = uuid();
      const state: ImageState = {
        file,
        id,
        isCompressed: false,
        status: 'PENDING',
      };

      return {
        ...states,
        [id]: state,
      };
    }, {});
    setImageStatesById((state) => ({ ...state, ...imageStates }));
    return imageStates;
  };

  const isValidFileFormat = (file: File) => {
    return file.type.split('/')[0] === 'image';
  };

  const resizeFileAndUpload = (imageState: ImageState) => {
    if (!isValidFileFormat(imageState.file)) {
      updateImageState({
        ...imageState,
        status: 'FAILED',
        errorType: 'PERMANENT',
      });
      return;
    }

    imageState.status = 'PENDING';
    updateImageState(imageState);

    resizeImageFile(imageState.file)
      .then((resizedFile) => {
        imageState.file = resizedFile;
        imageState.isCompressed = true;
        updateImageState(imageState);
        uploadSingleImage(imageState);
      })
      .catch((error) => {
        updateImageState({
          ...imageState,
          status: 'FAILED',
          errorType: 'TRANSIENT',
        });

        if (error instanceof Error) throw error;
      });
  };

  const resizeImagesAndUpload = (imageStatesById: ImageStatesById) => {
    Object.values(imageStatesById).forEach((imageState) => {
      resizeFileAndUpload(imageState);
    });
  };

  const handleSelectMediaFiles = (fileList: FileList) => {
    const files = Array.from(fileList);
    const imageStates = getImageInitialStates(files);
    resizeImagesAndUpload(imageStates);

    if (scene !== 'PHOTO_LIST') {
      setScene('PHOTO_LIST');
    }
  };

  const handleWarningCardPress = (id: string) => {
    setMediaOptionsModalId(id);
  };

  const handleCloseMediaOptionsModal = () => {
    setMediaOptionsModalId('');
  };

  const handleReuploadMediaPress = () => {
    const imageState = imageStatesById[mediaOptionsModalId];
    if (imageState.isCompressed) {
      uploadSingleImage(imageState);
    } else {
      resizeFileAndUpload(imageState);
    }

    handleCloseMediaOptionsModal();
  };

  const handleDeleteMediaPress = (id: string) => {
    setImageStatesById(omit(imageStatesById, id));
    handleCloseMediaOptionsModal();
  };

  const handleSendMediasPress = () => {
    setShowSubmitError(false);
    setIsSubmitting(true);
    const imageUrls = Object.values(imageStatesById).reduce(
      (result: string[], imgState) => {
        if ('url' in imgState) result.push(imgState.url);
        return result;
      },
      []
    );
    getMediaCode(imageUrls)
      .then((code) => {
        setCode(code);
        setScene('SUBMISSION_COMPLETE');
      })
      .catch((_) => {
        setShowSubmitError(true);
      })
      .finally(() => setIsSubmitting(false));
  };

  useEffect(() => {
    if (Object.keys(imageStatesById).length === 0) setScene('HOME');
  }, [imageStatesById]);

  switch (scene) {
    case 'HOME': {
      return <Home onSelectMediaFiles={handleSelectMediaFiles} />;
    }
    case 'PHOTO_LIST': {
      return (
        <PhotoList
          imageStates={Object.values(imageStatesById)}
          isSubmitting={isSubmitting}
          modalMediaId={mediaOptionsModalId}
          onCloseMediaOptionsModal={handleCloseMediaOptionsModal}
          onDeleteMediaPress={handleDeleteMediaPress}
          onReuploadMediaPress={handleReuploadMediaPress}
          onSelectMediaFiles={handleSelectMediaFiles}
          onSendMediasPress={handleSendMediasPress}
          onWarningCardPress={handleWarningCardPress}
          showErrorMessage={showSubmitError}
        />
      );
    }
    case 'SUBMISSION_COMPLETE': {
      return (
        <SubmissionComplete
          code={code}
          onNewSubmissionPress={handleNewSubmissionPress}
        />
      );
    }
    default: {
      return null;
    }
  }
}

export default App;
