import React from 'react';

import styles from '../assets/styles/Spinner.module.css';

type Props = {
  color?: string;
  radius?: number;
  thickness?: number;
};

const Spinner = (props: Props) => {
  const { color, radius = 9, thickness = 3 } = { ...props };
  const size = 2 * (radius + thickness);
  const circleStyle: React.CSSProperties = { borderWidth: thickness };

  return (
    <div aria-label="Carregando" data-testid="spinner">
      <div className={styles.wrapper} style={{ width: size, height: size }}>
        <div className={styles.spinnerLayer} style={{ borderColor: color }}>
          <div className={styles.circleClipper}>
            <div
              className={`${styles.circle} ${styles.leftCircle}`}
              style={circleStyle}
            />
          </div>
          <div className={styles.gapPatch}>
            <div className={styles.gapPatchCircle} />
          </div>
          <div className={styles.circleClipper}>
            <div
              className={`${styles.circle} ${styles.rightCircle}`}
              style={circleStyle}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Spinner;
